import React, { useEffect } from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import { getTranslatedText } from '../../../util/util';
import usePageTranslation from '../../../hooks/usePageTranslation';
import EditTranslationIcon from '../edit-translation-icon/EditTranslationIcon';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserProfileMutation, useGptBlueSocialShareMutation } from '../../../api-integration/secure/secure';
import { fullPageLoader, updateUser } from '../../../api-integration/commonSlice';

interface ISocialShareIcons {
    size?: number;
    shareObjectId: string; //id (e.g. tileCode or promptid) used in the API call to give callom coins to the user
    contentShareTitle: string; // Title of the content to be shared
    contentShareDesc: string; // Description of the content to be shared
    contentShareUrl: string; //Share URL used to form the content to be shared
    shareObject?: string; // 'tile' or 'prompt' to form the content
    sharePromptAuthor?: string; // in case of shareObject = 'prompt' >> Author of the prompt used in the content to be shared 
    socialShareHandler?: (content: string, network: string) => void;
    modalId?: string;
}

const SocialShareIcons: React.FC<ISocialShareIcons> = (props) => {
    const dispatch = useDispatch();
    const PageTranslation = usePageTranslation();
    const { TranslationEditFlag} = useSelector((state: any) => state.commonSlice);
    const loggedInUser = JSON.parse(localStorage.getItem('user') as string);
    const loggedInUserName = loggedInUser ? loggedInUser?.firstName + ' ' + loggedInUser?.lastName : '';
    
    const [gptBlueSocialShareAPI, { data: socialShareData, isLoading: isSocialShareLoading, isSuccess: isSocialShareSuccess, isError: isSocialShareError, error: socialShareError}] = 
        useGptBlueSocialShareMutation();
    const [getUserDetailsAPI, { data: userInfo, isLoading: isUserDetailLoding, isSuccess: isUserDetailSuccess, isError: isUserDetailError, error: userDetailError  }] =
        useGetUserProfileMutation();

    const title = props?.contentShareTitle;
    const description = props?.contentShareDesc;
    const shareURL = props?.contentShareUrl;
    let content = '';
    if(props?.shareObject === 'prompt'){
        content = `"${props?.contentShareTitle}" created by ${props?.sharePromptAuthor}\n\n${description}\n${props?.contentShareUrl}`;
    }else{
        content = `${props?.contentShareTitle}\n${description}\n${props?.contentShareUrl}`;
    }
    // When this function gets called, we will open the post modal
    const shareNetworkPostModal = (network: string) => {        
        if(props?.socialShareHandler){
            props?.socialShareHandler(content, network);
        }
    }

    const shareToNetwork = () => {
        const emailBody = `Hello \n\nI just wanted to share with you \n${content}  \n\nKind Regards, \n${loggedInUserName}`;
        let url = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(emailBody )}`;
        dispatch(fullPageLoader(true));
        gptBlueSocialShareAPI({
            "socialnet": "Email", 
            "shareObject": props?.shareObject ? props?.shareObject : 'tile', 
            "shareObjectId": props?.shareObjectId 
        });
        window.open(url, '_blank');
    };
    useEffect(() => {
        if (isSocialShareSuccess && socialShareData) {
            dispatch(fullPageLoader(true));
            getUserDetailsAPI({accountType: 'user'});
        }
        if (isSocialShareSuccess || isSocialShareError || socialShareError) {
            dispatch(fullPageLoader(false));
        }
    }, [isSocialShareSuccess, isSocialShareError, socialShareError]);

    useEffect(() => {
        if (isUserDetailSuccess) {
            dispatch(fullPageLoader(false));
            let user = JSON.parse(localStorage.getItem('user') as string);
            if(userInfo?.user?.totalCCPoints){
                user.totalCCPoints = userInfo?.user?.totalCCPoints;
                dispatch(updateUser(user));
                localStorage.setItem('user', JSON.stringify(user));
            }
        }
        if (isUserDetailError || userDetailError) {
            dispatch(fullPageLoader(false));
            //dispatch(updateAlertMessage({ status: 'error', message: userInfo?.message }));
        }
    }, [isUserDetailSuccess, isUserDetailError, userDetailError]);

    const XTwitterIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={props?.size ? props?.size :"35"} height={props?.size ? props?.size :"35"}>
          <path fill="white" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
        </svg>
    );

    const socialPlatforms = [
        { icon: FaLinkedin, label: 'linkedin.text', onClick: () => shareNetworkPostModal('LinkedIn'), color: '#0077B5', size: props?.size ? props?.size :38 },
        { icon: FaWhatsapp, label: 'whatsApp.text', onClick: () => shareNetworkPostModal('WhatsApp'), color: '#25D366', size: props?.size ? props?.size :38 },
        { icon: XTwitterIcon, label: 'twitter.text', onClick: () => shareNetworkPostModal('Twitter'), color: '#000000', size: props?.size ? props?.size :38 },
        { icon: FaEnvelope, label: 'email.text', onClick: () => shareToNetwork(), color: 'var(--bs-dark)', size: props?.size ? props?.size :38 },
    ];

    

    return (
        <div className='d-flex justify-content-evenly'>
            {socialPlatforms.map((platform, index) => {
                const Icon = platform.icon;
                return (
                    <div className='px-3 d-flex flex-column' key={index} >
                        <div 
                            className='d-flex justify-content-center'
                            data-bs-toggle="modal"
                            data-bs-target={`#${props?.modalId}`}
                        >
                            <div className='cursor-pointer' onClick={platform.onClick} style={{ borderRadius: '50%', width: props?.size ? `${props?.size + 20}px` :'60px', height: props?.size ? `${props?.size + 20}px` :'60px', background: platform.color, display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Icon size={platform.size} color="white" />
                            </div>
                        </div>
                        <div 
                            className='d-flex justify-content-center'
                            data-bs-toggle="modal"
                            data-bs-target={`#${props?.modalId}`}
                            >
                            <div className='cursor-pointer' onClick={platform.onClick}>{getTranslatedText(platform.label,PageTranslation)}</div>
                            {TranslationEditFlag && (
                                <EditTranslationIcon translationKey={platform.label} translationType='text'/>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default SocialShareIcons;