import React, { useEffect } from 'react';
//import { IconType } from 'react-icons';
//import { FaTwitter, FaEnvelope, FaWhatsapp, FaLinkedinIn } from 'react-icons/fa';
import TooltipComponent from '../bootstrap-component/tooltip-component';
// import { useGetUserProfileMutation, useGptBlueSocialShareMutation } from '../../../api-integration/secure/secure';
import {  useSelector } from 'react-redux';
// import { fullPageLoader,  updateUser } from '../../../api-integration/commonSlice';
import { initializeModal } from '../../../util/util';
import { getTranslatedText } from '../../../util/util';
import usePageTranslation from '../../../hooks/usePageTranslation';
import EditTranslationIcon from "../edit-translation-icon/EditTranslationIcon";
import SocialShareIcons from './social-share-icons';



interface SocialShareProps {
    id: string; // id of the modal
    shareObjectId: string; //id (e.g. tileCode or promptid) used in the API call to give callom coins to the user
    contentShareTitle: string; // Title of the content to be shared
    contentShareDesc: string; // Description of the content to be shared
    contentShareUrl: string; //Share URL used to form the content to be shared
    contentShareImageUrl?: string; // Not used
    contentShareParamId?: string; // Not used
    shareObject?: string; // 'tile' or 'prompt' to form the content
    sharePromptAuthor?: string; // in case of shareObject = 'prompt' >> Author of the prompt used in the content to be shared 
    socialShareHandler: (content: string, network: string) => void;
    sharePostModalId?: string;
    //sharePromptImage?: string;
}

const SocialShare: React.FC<SocialShareProps> = ({ id, shareObjectId, contentShareTitle, contentShareDesc, contentShareUrl, contentShareImageUrl, contentShareParamId, shareObject, sharePromptAuthor, socialShareHandler, sharePostModalId }) => {
    const { TranslationEditFlag} = useSelector((state: any) => state.commonSlice);

    const PageTranslation = usePageTranslation();

    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(id);
    
        return () => {
            // Call the initializeModal function on unmount/cleanup
            initializeModal(id);
        };
    }, [id]);

    return (
        <div className="modal fade modal-draggable" data-bs-backdrop="false" id={id} tabIndex={-1} aria-labelledby={`${id}Label`} aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`${id}Label`}>
                            {getTranslatedText('socialShare.card.title',PageTranslation)}
                            {TranslationEditFlag && (
                                <EditTranslationIcon translationKey="socialShare.card.title" translationType='text'/>
                            )}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div className="modal-body" >
                        <SocialShareIcons 
                            shareObjectId={shareObjectId}
                            contentShareTitle={contentShareTitle}
                            contentShareDesc={contentShareDesc}
                            contentShareUrl={contentShareUrl}
                            shareObject={shareObject}
                            sharePromptAuthor={sharePromptAuthor}
                            socialShareHandler={socialShareHandler}
                            modalId={sharePostModalId}
                        />
                        <div className="modal-footer mt-3">
                            <TooltipComponent title={getTranslatedText("close.tooltip", PageTranslation)}>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                    {getTranslatedText("close.text", PageTranslation)}
                                </button>
                            </TooltipComponent>
                            {TranslationEditFlag && (
                                <>
                                    <EditTranslationIcon translationKey="close.text" translationType='text'/>
                                    <EditTranslationIcon translationKey="close.tooltip" translationType='tooltip' />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocialShare;